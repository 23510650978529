(function() {

const X_MOVE = 100
const Y_MOVE = 100

const debounce = (func, delay) => {
	let inDebounce
	return function () {
		const context = this
		const args = arguments
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() => func.apply(context, args), delay)
	}
}

const throttle = (func, limit) => {
	let inThrottle
	return function () {
		const args = arguments
		const context = this
		if (!inThrottle) {
			func.apply(context, args)
			inThrottle = true
			setTimeout(() => inThrottle = false, limit)
		}
	}
}

function calculateMovement(position) {
	return {
		x: 2 * X_MOVE / screen.width * position.x - X_MOVE,
		y: 2 * Y_MOVE / screen.height * position.y - Y_MOVE,
	}
};

function getMousePos(event) {
	let posX = 0;
	let posY = 0;

	if (!event) event = window.event;

	posX = event.clientX;
	posY = event.clientY;

	return {
		x: posX,
		y: posY
	}
}

const headerSvg = document.querySelector('.header-svg')
const logoImage = document.querySelector('.logo_img')
const links = document.querySelectorAll('nav h2')
const moon = document.querySelector('.moon')
const sun = document.querySelector('.sun')

moon.addEventListener('click', (e) => {
	document.body.classList.remove('is-night')
	document.body.classList.add('is-day')
})

sun.addEventListener('click', (e) => {
	document.body.classList.remove('is-day')
	document.body.classList.add('is-night')
})

function onMouseMove(e) {
	const pos = getMousePos(e)
	const { x, y } = calculateMovement(pos)
	// logoImage.style.transform = `translate(${Math.min(x, 200) / 2}px, ${Math.min(y, 200) / 2}px)`
	moveLogo(x, y)
	moveLinks(x, y)
}

function moveLogo(x, y) {
	logoImage.style.backgroundPosition = `${Math.min(x, 200) / 2 - 100}px ${Math.min(y, 200) / 2 - 100}px`
}

function moveLinks(x, y) {
	links.forEach(link => {
		let { bgX, bgY } = link.dataset
		bgX = -Number(bgX)
		bgY = -Number(bgY)
		const newX = (x + bgX) / 2
		const newY = (y + bgY) / 2
		link.style.backgroundPosition = `${newX}px ${newY}px`
	})
}

moveLinks(0, 0)

window.addEventListener('mousemove', throttle(onMouseMove, 20));

let currX
let currY

const scale = (num, in_min, in_max, out_min, out_max) => {
	return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

const DEVICE_TILT_THROTTLE = 100
const isDesktop = !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|BB10|mobi|tablet|opera mini|nexus 7)/i)

if (isDesktop === false) {
	document.body.classList.add('is-mobile')
}

if (window.DeviceOrientationEvent && isDesktop === false) {
	function onDeviceOrientation(e) {
		// console.log(e)
		let x = scale(e.gamma, -90, 90, 0, X_MOVE * 8)
		let y = scale(e.beta, -180, 180, 0, Y_MOVE * 6)
		const landscape = Math.abs(window.orientation) === 90

		let linksX = scale(e.gamma, -90, 90, -X_MOVE * 4, X_MOVE)
		let linksY = scale(e.beta, -180, 180, -Y_MOVE * 4, Y_MOVE)

		logoImage.style.backgroundPosition =
			landscape ? `${-y}px ${-x}px` : `${-x}px ${-y}px`
		landscape ? moveLinks(-linksY, -linksX) : moveLinks(-linksX, -linksY)
	}
	window.addEventListener('deviceorientation', throttle(onDeviceOrientation, DEVICE_TILT_THROTTLE), false);
}


})()